import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import idID from "antd/lib/locale/id_ID";
import { ApolloProvider } from "@apollo/client";
import App from "./App";
import apolloClient from "./apollo/client";
import Firebase, { FirebaseContext } from "./components/firebase";
import UserProvider from "./contexts/UserContext";
import { LocalStorageProvider } from "./contexts/LocationContext";

import "./index.css";
import { LocaleContextProvider } from "./config/localization";

ReactDOM.render(
  <React.StrictMode>
    <LocaleContextProvider>
      <FirebaseContext.Provider value={new Firebase()}>
        <ApolloProvider client={apolloClient}>
          <UserProvider>
            <LocalStorageProvider>
              <ConfigProvider locale={idID}>
                <App />
              </ConfigProvider>
            </LocalStorageProvider>
          </UserProvider>
        </ApolloProvider>
      </FirebaseContext.Provider>
    </LocaleContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
