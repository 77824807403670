/* eslint-disable indent */
/* eslint-disable import/no-extraneous-dependencies */
import React, { createContext, useState, useContext, useEffect } from "react";
import firebase from "firebase/app";
import { useApolloClient } from "@apollo/client";
import { FirebaseContext } from "../../components/firebase";
import {
  FetchUserDetailsDocument,
  FetchUserDetailsQuery,
  FetchUserDetailsQueryVariables,
} from "../../apollo/gen-types";

export interface KarunaUser extends firebase.User {
  leadId?: string;
  vendorName?: string;
  vendorLocation?: {
    state: string;
    district: string;
    pincodes?: string[];
  };
  role: "hospital" | "food" | "oxygen" | "admin" | "cityAdmin" | "normal";
  phoneNumber: firebase.User["phoneNumber"];
}

export const UserContext = createContext<{
  user: KarunaUser | null;
  loading: boolean;
  // eslint-disable-next-line no-unused-vars
  updateUserFields(fieldsToUpdate: Partial<KarunaUser>): void;
}>({
  user: null,
  loading: true,
  updateUserFields: () => {},
});

const UserProvider: React.FC = ({ ...props }) => {
  const firebaseContext = useContext(FirebaseContext);
  if (!firebaseContext) {
    return null;
  }
  // eslint-disable-next-line prefer-destructuring
  let currentUser: any = firebaseContext.app.auth().currentUser;

  if (currentUser) {
    // currentUser.updateProfile({
    //   displayName: null,
    // });
    currentUser = {
      role: "normal",
      ...currentUser,
    };
  }
  const [user, setUser] = useState<{
    user: KarunaUser | null;
    loading: boolean;
  }>({
    user: currentUser,
    loading: !currentUser,
  });

  const client = useApolloClient();
  const userDetails = React.useCallback(
    () =>
      client.query<FetchUserDetailsQuery, FetchUserDetailsQueryVariables>({
        query: FetchUserDetailsDocument,
        fetchPolicy: "no-cache",
      }),
    [client]
  );

  async function getUserData() {
    if (firebaseContext) {
      firebaseContext.app.auth().onAuthStateChanged(async (userAuth) => {
        if (userAuth) {
          const userObj: KarunaUser = {
            ...userAuth,
            role: "normal",
            phoneNumber: "",
          };
          const roleClaim = userAuth
            .getIdTokenResult(true)
            .then((idTokenResult) => idTokenResult.claims);
          const claims = await roleClaim;
          if (claims.role && claims.role !== "normal") {
            const fetchedUserDetails = await userDetails();
            const data = fetchedUserDetails.data.userDetails;
            userObj.vendorName = data.vendorName || undefined;
            userObj.leadId = data.leadId || undefined;
            userObj.vendorLocation = data.vendorLocation;
            userObj.phoneNumber = data.phoneNumber;
          }
          userObj.role = claims.role || "normal";
          setUser({ user: userObj, loading: false });
        } else {
          setUser({ user: userAuth, loading: false });
        }
      });
    }
  }

  function updateUserFields(fieldsToUpdate: Partial<KarunaUser>) {
    setUser((userState) => {
      if (userState.user) {
        return {
          loading: userState.loading,
          user: {
            ...userState.user,
            ...fieldsToUpdate,
          },
        };
      }
      return userState;
    });
  }

  useEffect(() => {
    getUserData();
  }, [firebaseContext]);

  return (
    <UserContext.Provider value={{ ...user, updateUserFields }}>
      {props.children}
    </UserContext.Provider>
  );
};

export default UserProvider;
