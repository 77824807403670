import { CountryCode } from "libphonenumber-js";
import React, { useContext } from "react";

export const localizationConfig: {
  locale: CountryCode;
  pincodeLen: number;
  translations: {
    state: string;
    district: string;
    pincode: string;
  };
} = {
  locale: "ID",
  pincodeLen: 5,
  translations: {
    state: "Province",
    district: "Kecamatan",
    pincode: "Postal Code",
  },
};

export const LocaleContext = React.createContext<typeof localizationConfig>({
  locale: "IN",
  pincodeLen: 6,
  translations: {
    state: "State",
    district: "District",
    pincode: "Pincode",
  },
});

export const LocaleContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  return (
    <LocaleContext.Provider value={localizationConfig}>
      {children}
    </LocaleContext.Provider>
  );
};

export const useLocaleContext = () => {
  const { locale, pincodeLen, translations } = useContext(LocaleContext);
  return { locale, pincodeLen, translations };
};
