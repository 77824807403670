/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  makeVar,
} from "@apollo/client";
import { ReadFieldFunction } from "@apollo/client/cache/core/types/common";
import { setContext } from "@apollo/client/link/context";
import dayjs from "dayjs";
import firebase from "firebase/app";
import "firebase/auth";
import { Comment } from "../gen-types";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  const user = firebase.auth().currentUser;
  if (!user) {
    return Promise.resolve({
      headers: {
        ...headers,
        authorization: "",
      },
    });
  }
  return user.getIdToken().then((token) => {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });
});

// eslint-disable-next-line no-unused-vars
function offsetFromCursor(
  items: string | any[],
  cursor: any,
  readField: ReadFieldFunction
) {
  // Search from the back of the list because the cursor we're
  // looking for is typically the ID of the last item.
  for (let i = items.length - 1; i >= 0; i -= 1) {
    const item = items[i];
    // Using readField works for both non-normalized objects
    // (returning item.id) and normalized references (returning
    // the id field from the referenced entity object), so it's
    // a good idea to use readField when you're not sure what
    // kind of elements you're dealing with.
    const readId = readField("id", item);
    if (readId === cursor) {
      // Add one because the cursor identifies the item just
      // before the first item in the page we care about.
      return i + 1;
    }
  }
  // Report that the cursor could not be found.
  return -1;
}

const existsMoreBroadcastsState = makeVar<{
  [key: string]: boolean;
}>({});
const existsMoreResourcesState = makeVar<{
  [key: string]: boolean;
}>({});
const existsMoreCommentsState = makeVar<{
  [key: string]: boolean;
}>({});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Comment: {
        fields: {
          children: {
            merge(
              existing: any[],
              incoming: any[],
              { readField, mergeObjects }
            ) {
              const merged: any[] = existing ? existing.slice(0) : [];
              const childrenIdToIndex: Record<string, number> =
                Object.create(null);
              if (existing) {
                existing.forEach((comment, index) => {
                  childrenIdToIndex[readField<string>("id", comment)!] = index;
                });
              }
              incoming.forEach((comment) => {
                const id = readField<string>("id", comment)!;
                const index = childrenIdToIndex[id];
                if (typeof index === "number") {
                  // Merge the new author data with the existing author data.
                  merged[index] = mergeObjects(merged[index], comment);
                } else {
                  // First time we've seen this author in this array.
                  childrenIdToIndex[id] = merged.length;
                  merged.push(comment);
                }
              });
              return merged.sort(
                (a: any, b: any) =>
                  dayjs(readField<string>("timestamp", a)!).valueOf() -
                  dayjs(readField<string>("timestamp", b)!).valueOf()
              );
            },
          },
        },
      },
      Query: {
        fields: {
          getBroadcastsByLocation: {
            keyArgs: ["input", ["location"]],
            merge(existing, incoming, { args, readField }) {
              const { pagination } = args?.input;
              if (incoming.length < (pagination.limit || 1)) {
                existsMoreBroadcastsState({
                  ...existsMoreBroadcastsState(),
                  [JSON.stringify(args!.input!.location)]: false,
                });
              } else {
                existsMoreBroadcastsState({
                  ...existsMoreBroadcastsState(),
                  [JSON.stringify(args!.input!.location)]: true,
                });
              }
              if (pagination?.id) {
                const merged = existing ? existing.slice(0) : [];
                let offset = offsetFromCursor(
                  merged,
                  pagination?.id,
                  readField
                );
                // If we couldn't find the cursor, default to appending to
                // the end of the list, so we don't lose any data.
                if (offset < 0) offset = merged.length;
                // Now that we have a reliable offset, the rest of this logic
                // is the same as in offsetLimitPagination.
                for (let i = 0; i < incoming.length; i += 1) {
                  merged[offset + i] = incoming[i];
                }
                return merged;
              }
              return incoming;
            },
          },
          getResourceByLocation: {
            keyArgs: ["input", ["type", "location", "verified"]],
            merge(existing, incoming, { args, readField }) {
              const { pagination } = args?.input;
              if (incoming.length < (pagination.limit || 1)) {
                existsMoreResourcesState({
                  ...existsMoreResourcesState(),
                  [JSON.stringify({ ...args!.input, pagination: undefined })]:
                    false,
                });
              } else {
                existsMoreResourcesState({
                  ...existsMoreResourcesState(),
                  [JSON.stringify({ ...args!.input, pagination: undefined })]:
                    true,
                });
              }
              if (pagination?.id) {
                const merged = existing ? existing.slice(0) : [];
                let offset = offsetFromCursor(
                  merged,
                  pagination?.id,
                  readField
                );
                // If we couldn't find the cursor, default to appending to
                // the end of the list, so we don't lose any data.
                if (offset < 0) offset = merged.length;
                // Now that we have a reliable offset, the rest of this logic
                // is the same as in offsetLimitPagination.
                for (let i = 0; i < incoming.length; i += 1) {
                  merged[offset + i] = incoming[i];
                }
                return merged;
              }
              return incoming;
            },
          },
          existsMoreResources: {
            read(curr, { args }) {
              const existsMore =
                existsMoreResourcesState()[
                  JSON.stringify({ ...args!.input, pagination: undefined })
                ];
              return existsMore;
            },
          },
          comments: {
            keyArgs: ["input", ["resourceId", "thing"]],
            merge(existing, incoming: Comment[], { args, readField, cache }) {
              const { pagination, parentId } = args?.input;
              if (!parentId) {
                if (incoming.length < (pagination.limit || 1)) {
                  existsMoreCommentsState({
                    ...existsMoreCommentsState(),
                    [JSON.stringify({
                      ...args!.input,
                      pagination: undefined,
                      parentId: undefined,
                    })]: false,
                  });
                } else {
                  existsMoreCommentsState({
                    ...existsMoreCommentsState(),
                    [JSON.stringify({
                      ...args!.input,
                      pagination: undefined,
                      parentId: undefined,
                    })]: true,
                  });
                }
              } else if (pagination?.id && pagination?.timestamp) {
                const merged = existing ? { ...existing } : {};
                return merged;
              }
              const merged = existing ? { ...existing } : {};
              const newComments = incoming.slice(0);
              newComments.forEach((comment) => {
                merged[readField("id", comment) as string] = comment;
              });
              return merged;
            },
            read(existing) {
              const result = existing && Object.values(existing);
              return result;
            },
          },
          existsMoreComments: {
            read(curr, { args }) {
              const existsMore =
                existsMoreCommentsState()[
                  JSON.stringify({
                    ...args!.input,
                    pagination: undefined,
                    parentId: undefined,
                  })
                ];
              return existsMore;
            },
          },
          existsMore: {
            read(curr, { args }) {
              const existsMore =
                existsMoreBroadcastsState()[JSON.stringify(args!.input)];
              return existsMore;
            },
          },
        },
      },
    },
  }),
});
export default client;
