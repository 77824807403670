/* eslint-disable import/no-extraneous-dependencies */
import firebase from "firebase/app";
import { localizationConfig } from "../../config/localization";
import "firebase/auth";
import "firebase/messaging";

const config = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  databaseURL: process.env.REACT_APP_DATABASEURL,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  // measurementId: process.env.REACT_APP_MEASUREMENTID,
};

class Firebase {
  firebase: typeof firebase;

  app: firebase.app.App;

  // analytics: firebase.analytics.Analytics;

  messaging: firebase.messaging.Messaging | undefined;

  // db: firebase.firestore.Firestore;

  constructor() {
    const app = firebase.initializeApp(config);
    this.firebase = firebase;
    this.app = app;
    // this.analytics = app.analytics();
    // this.messaging = null;
    if (firebase.messaging.isSupported()) {
      this.messaging = app.messaging();
    }
  }

  static uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },
    siteName: "Karuna",
    tosUrl: "https://pathcheck.org",
    privacyPolicyUrl: "https://pathcheck.org",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      {
        clientId: process.env.REACT_APP_CLIENT_ID,
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        customParameters: {
          // Forces account selection even when one account
          // is available.
          prompt: "select_account",
        },
      },
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      firebase.auth.EmailAuthProvider.PROVIDER_ID,
      {
        provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        requireDisplayName: true,
        recaptchaParameters: {
          size: "invisible",
          badge: "bottomleft",
        },
        defaultCountry: localizationConfig.locale,
      },
    ],
    credentialHelper: "googleyolo",
  };
}

export default Firebase;
