/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Timestamp: any;
};

export type AddCommentInput = {
  resourceId?: Maybe<Scalars['String']>;
  thing: Thing;
  content: Scalars['String'];
  pinned?: Maybe<Scalars['Boolean']>;
  replyTo?: Maybe<Scalars['String']>;
};

export type AddLikeInput = {
  resourceId?: Maybe<Scalars['ID']>;
  action: LikeDislike;
  thing: Thing;
};

export enum AgeGroup {
  Fortyfiveplus = 'FORTYFIVEPLUS',
  Eigthteenplus = 'EIGTHTEENPLUS',
  Both = 'BOTH'
}

export type BedData = {
  __typename?: 'BedData';
  normal?: Maybe<Scalars['Int']>;
  oxygen?: Maybe<Scalars['Int']>;
  ventilator?: Maybe<Scalars['Int']>;
  icu?: Maybe<Scalars['Int']>;
};

export type BedInput = {
  normal?: Maybe<Scalars['Int']>;
  oxygen?: Maybe<Scalars['Int']>;
  ventilator?: Maybe<Scalars['Int']>;
  icu?: Maybe<Scalars['Int']>;
};

export type Broadcast = {
  __typename?: 'Broadcast';
  id: Scalars['ID'];
  location: BroadcastLocation;
  title: Scalars['String'];
  description: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
};

export type BroadcastByLocationInput = {
  location: BroadcastLocationInput;
  pagination?: Maybe<PaginationInput>;
};

export type BroadcastId = {
  id: Scalars['ID'];
};

export type BroadcastLocation = {
  __typename?: 'BroadcastLocation';
  state: Scalars['String'];
  district: Scalars['String'];
  pincodes?: Maybe<Array<Scalars['String']>>;
};

export type BroadcastLocationInput = {
  state?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  pincodes?: Maybe<Array<Scalars['String']>>;
};

export type BroadcastMutation = {
  location: BroadcastLocationInput;
  title: Scalars['String'];
  description: Scalars['String'];
  publishTill?: Maybe<Scalars['Timestamp']>;
};

export type BroadcastNotificationInput = {
  state: Scalars['String'];
  district: Scalars['String'];
  pincode?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  timestamp: Scalars['Timestamp'];
  reportsCount: Scalars['Int'];
  reports: Array<Maybe<Report>>;
  content: Scalars['String'];
  userId: Scalars['ID'];
  byVerifiedUser: Scalars['Boolean'];
  children: Array<CommentWithoutChild>;
  childrenCount: Scalars['Int'];
  pinned: Scalars['Boolean'];
  pinnedBy?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  photoURL?: Maybe<Scalars['String']>;
};

export type CommentInput = {
  resourceId?: Maybe<Scalars['String']>;
  thing: Thing;
  parentId?: Maybe<Scalars['String']>;
  pagination?: Maybe<CommentPaginationInput>;
};

export type CommentPaginationInput = {
  pinned?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
};

export type CommentWithoutChild = {
  __typename?: 'CommentWithoutChild';
  id: Scalars['ID'];
  timestamp: Scalars['Timestamp'];
  reportsCount: Scalars['Int'];
  reports: Array<Report>;
  content: Scalars['String'];
  userId: Scalars['ID'];
  byVerifiedUser: Scalars['Boolean'];
  username: Scalars['String'];
  photoURL?: Maybe<Scalars['String']>;
};

export type CommentsWithoutPagination = {
  resourceId?: Maybe<Scalars['String']>;
  thing: Thing;
};

export type CreateResourceInput = {
  location?: Maybe<CreateResourceLocationInput>;
  verified: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  contact: Scalars['String'];
  address: Scalars['String'];
  type: ResourceType;
  bedData?: Maybe<BedInput>;
  oxygenData?: Maybe<OxygenInput>;
  foodData?: Maybe<FoodInput>;
  coordinates: LatLngInput;
};

export type CreateResourceLocationInput = {
  pincode: Scalars['String'];
};

export type DeleteResourceInput = {
  id: Scalars['ID'];
};

export type FoodData = {
  __typename?: 'FoodData';
  homeDelivery: Scalars['Boolean'];
  pureVeg: Scalars['Boolean'];
};

export type FoodInput = {
  homeDelivery: Scalars['Boolean'];
  pureVeg: Scalars['Boolean'];
};

export type GetSubscriptionInput = {
  token: Scalars['String'];
};

export type LatLng = {
  __typename?: 'LatLng';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type LatLngInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type Like = {
  __typename?: 'Like';
  count: Scalars['Int'];
  likedByUser?: Maybe<LikeDislike>;
  id: Scalars['ID'];
};

export enum LikeDislike {
  Like = 'LIKE',
  Dislike = 'DISLIKE'
}

export type LikeInput = {
  resourceId?: Maybe<Scalars['ID']>;
  thing: Thing;
};

export type LocationInput = {
  state: Scalars['String'];
  district: Scalars['String'];
};

export type MiscResource = {
  __typename?: 'MiscResource';
  name: Scalars['String'];
  title: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addComment: Comment;
  addLike: Like;
  approveUser: UnapprovedUser;
  createBroadcast: Broadcast;
  createResource: Resource;
  createUser: User;
  createUserWithRole: User;
  deleteResource: Scalars['Boolean'];
  subscribeBroadcastNotification: SubscriptionResult;
  updateBroadcastSubscription: SubscriptionResult;
  updateResource: Resource;
};


export type MutationAddCommentArgs = {
  input: AddCommentInput;
};


export type MutationAddLikeArgs = {
  input: AddLikeInput;
};


export type MutationApproveUserArgs = {
  input: UnapprovedUserMutation;
};


export type MutationCreateBroadcastArgs = {
  input: BroadcastMutation;
};


export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};


export type MutationCreateUserArgs = {
  input: UserMutation;
};


export type MutationCreateUserWithRoleArgs = {
  input: UserRoleMutation;
};


export type MutationDeleteResourceArgs = {
  input: DeleteResourceInput;
};


export type MutationSubscribeBroadcastNotificationArgs = {
  input: BroadcastNotificationInput;
};


export type MutationUpdateBroadcastSubscriptionArgs = {
  input: UpdateBroadcastNotificationInput;
};


export type MutationUpdateResourceArgs = {
  input: UpdateResourceInput;
};

export type OxygenData = {
  __typename?: 'OxygenData';
  cylinder: Scalars['Boolean'];
  refill: Scalars['Boolean'];
  can: Scalars['Boolean'];
};

export type OxygenInput = {
  cylinder: Scalars['Boolean'];
  refill: Scalars['Boolean'];
  can: Scalars['Boolean'];
};

export type PaginationInput = {
  limit?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  comments: Array<Comment>;
  existsMore: Scalars['Boolean'];
  existsMoreComments: Scalars['Boolean'];
  existsMoreResources: Scalars['Boolean'];
  getBroadcast: Broadcast;
  getBroadcastSubscription?: Maybe<Subscription>;
  getBroadcastsByLocation: Array<Broadcast>;
  getBroadcastsByUser: Array<Broadcast>;
  getDistricts: Array<Scalars['String']>;
  getPincodes: Array<Scalars['Int']>;
  getPincodesForDistrict: Array<Scalars['String']>;
  getResource: Resource;
  getResourceByLocation: Array<Resource>;
  getResourcesByUser: Array<Resource>;
  getStates: Array<Scalars['String']>;
  likes: Like;
  miscResources: Array<MiscResource>;
  unapprovedUsers: Array<UnapprovedUser>;
  userDetails: User;
};


export type QueryCommentsArgs = {
  input: CommentInput;
};


export type QueryExistsMoreArgs = {
  input: BroadcastLocationInput;
};


export type QueryExistsMoreCommentsArgs = {
  input: CommentsWithoutPagination;
};


export type QueryExistsMoreResourcesArgs = {
  input: ResourceByLocationWithoutPagination;
};


export type QueryGetBroadcastArgs = {
  input: BroadcastId;
};


export type QueryGetBroadcastSubscriptionArgs = {
  input: GetSubscriptionInput;
};


export type QueryGetBroadcastsByLocationArgs = {
  input: BroadcastByLocationInput;
};


export type QueryGetDistrictsArgs = {
  state: Scalars['String'];
};


export type QueryGetPincodesArgs = {
  input: Scalars['String'];
};


export type QueryGetPincodesForDistrictArgs = {
  district: Scalars['String'];
};


export type QueryGetResourceArgs = {
  input: ResourceId;
};


export type QueryGetResourceByLocationArgs = {
  input: ResourceByLocationInput;
};


export type QueryLikesArgs = {
  input: LikeInput;
};


export type QueryUnapprovedUsersArgs = {
  input?: Maybe<UnapprovedUserFilters>;
};

export type Report = {
  __typename?: 'Report';
  reportType: Scalars['String'];
};

export type Resource = {
  __typename?: 'Resource';
  id: Scalars['ID'];
  location: ResourceLocation;
  verified: Scalars['Boolean'];
  title: Scalars['String'];
  description: Scalars['String'];
  updatedAt: Scalars['Timestamp'];
  contact: Scalars['String'];
  address: Scalars['String'];
  type: ResourceType;
  bedData?: Maybe<BedData>;
  oxygenData?: Maybe<OxygenData>;
  foodData?: Maybe<FoodData>;
  byUser: Scalars['Boolean'];
  coordinates: LatLng;
};

export type ResourceByLocationInput = {
  type: ResourceType;
  location: ResourceLocationInput;
  verified: Scalars['Boolean'];
  pagination?: Maybe<PaginationInput>;
};

export type ResourceByLocationWithoutPagination = {
  type: ResourceType;
  location: ResourceLocationInput;
  verified: Scalars['Boolean'];
};

export type ResourceId = {
  id: Scalars['ID'];
};

export type ResourceLocation = {
  __typename?: 'ResourceLocation';
  state: Scalars['String'];
  district: Scalars['String'];
  pincode: Scalars['String'];
};

export type ResourceLocationInput = {
  pincode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
};

export enum ResourceType {
  Hospital = 'hospital',
  Oxygen = 'oxygen',
  Food = 'food'
}

export type Subscription = {
  __typename?: 'Subscription';
  state: Scalars['String'];
  district: Scalars['String'];
  pincode?: Maybe<Scalars['String']>;
};

export type SubscriptionResult = {
  __typename?: 'SubscriptionResult';
  success: Scalars['Boolean'];
};

export enum Thing {
  Lead = 'lead',
  Broadcast = 'broadcast',
  Feedback = 'feedback'
}


export enum UnapprovedRoles {
  Hospital = 'hospital',
  Food = 'food',
  Oxygen = 'oxygen',
  CityAdmin = 'cityAdmin',
  Normal = 'normal',
  Admin = 'admin'
}

export type UnapprovedUser = {
  __typename?: 'UnapprovedUser';
  id: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  vendorName?: Maybe<Scalars['String']>;
  vendorLocation: VendorLocation;
  role: UnapprovedRoles;
  phoneNumber: Scalars['String'];
};

export type UnapprovedUserFilters = {
  type?: Maybe<UnapprovedRoles>;
  location?: Maybe<LocationInput>;
};

export type UnapprovedUserMutation = {
  id: Scalars['String'];
};

export type UpdateBroadcastNotificationInput = {
  state?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  pincode?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

export type UpdateResourceInput = {
  resourceId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  bedData?: Maybe<BedInput>;
  oxygenData?: Maybe<OxygenInput>;
  foodData?: Maybe<FoodInput>;
};

export type User = {
  __typename?: 'User';
  name: Scalars['String'];
  email: Scalars['String'];
  leadId?: Maybe<Scalars['String']>;
  vendorName?: Maybe<Scalars['String']>;
  vendorLocation: VendorLocation;
  phoneNumber: Scalars['String'];
};

export type UserMutation = {
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type UserRoleMutation = {
  role: UnapprovedRoles;
  vendorName?: Maybe<Scalars['String']>;
  vendorLocation: VendorLocationInput;
  phoneNumber: Scalars['String'];
};

export enum Vaccine {
  Covaxin = 'COVAXIN',
  Covishield = 'COVISHIELD',
  Sputnikv = 'SPUTNIKV'
}

export type VaccineAlert = {
  __typename?: 'VaccineAlert';
  state?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  pincode?: Maybe<Scalars['String']>;
  ageGroupPreference?: Maybe<AgeGroup>;
  vaccinePreference?: Maybe<Vaccine>;
  email?: Maybe<Scalars['String']>;
  fcmToken?: Maybe<Scalars['String']>;
};

export type VendorLocation = {
  __typename?: 'VendorLocation';
  state: Scalars['String'];
  district: Scalars['String'];
};

export type VendorLocationInput = {
  state?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  pincode: Scalars['String'];
};

export type State_Helplines = {
  __typename?: 'state_helplines';
  state?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
};

export type GetBroadcastSubscriptionQueryVariables = Exact<{
  input: GetSubscriptionInput;
}>;


export type GetBroadcastSubscriptionQuery = (
  { __typename?: 'Query' }
  & { getBroadcastSubscription?: Maybe<(
    { __typename?: 'Subscription' }
    & Pick<Subscription, 'state' | 'district' | 'pincode'>
  )> }
);

export type SubscribeBroadcastNotificationMutationVariables = Exact<{
  input: BroadcastNotificationInput;
}>;


export type SubscribeBroadcastNotificationMutation = (
  { __typename?: 'Mutation' }
  & { subscribeBroadcastNotification: (
    { __typename?: 'SubscriptionResult' }
    & Pick<SubscriptionResult, 'success'>
  ) }
);

export type UpdateBroadcastSubscriptionMutationVariables = Exact<{
  input: UpdateBroadcastNotificationInput;
}>;


export type UpdateBroadcastSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & { updateBroadcastSubscription: (
    { __typename?: 'SubscriptionResult' }
    & Pick<SubscriptionResult, 'success'>
  ) }
);

export type GetBroadcastsByLocationQueryVariables = Exact<{
  input: BroadcastByLocationInput;
}>;


export type GetBroadcastsByLocationQuery = (
  { __typename?: 'Query' }
  & { getBroadcastsByLocation: Array<(
    { __typename?: 'Broadcast' }
    & Pick<Broadcast, 'title' | 'description' | 'updatedAt' | 'id'>
    & { location: (
      { __typename?: 'BroadcastLocation' }
      & Pick<BroadcastLocation, 'state' | 'district' | 'pincodes'>
    ) }
  )> }
);

export type GetBroadcastQueryVariables = Exact<{
  input: BroadcastId;
}>;


export type GetBroadcastQuery = (
  { __typename?: 'Query' }
  & { getBroadcast: (
    { __typename?: 'Broadcast' }
    & Pick<Broadcast, 'title' | 'description' | 'updatedAt' | 'id'>
    & { location: (
      { __typename?: 'BroadcastLocation' }
      & Pick<BroadcastLocation, 'state' | 'district' | 'pincodes'>
    ) }
  ) }
);

export type ExistsMoreBroadcastsQueryVariables = Exact<{
  input: BroadcastLocationInput;
}>;


export type ExistsMoreBroadcastsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'existsMore'>
);

export type CreateBroadcastMutationVariables = Exact<{
  input: BroadcastMutation;
}>;


export type CreateBroadcastMutation = (
  { __typename?: 'Mutation' }
  & { createBroadcast: (
    { __typename?: 'Broadcast' }
    & Pick<Broadcast, 'title' | 'description' | 'updatedAt' | 'id'>
    & { location: (
      { __typename?: 'BroadcastLocation' }
      & Pick<BroadcastLocation, 'pincodes' | 'state' | 'district'>
    ) }
  ) }
);

export type AddCommentMutationVariables = Exact<{
  input: AddCommentInput;
}>;


export type AddCommentMutation = (
  { __typename?: 'Mutation' }
  & { addComment: (
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'timestamp' | 'content' | 'userId' | 'username' | 'byVerifiedUser' | 'childrenCount' | 'pinned' | 'pinnedBy' | 'photoURL'>
    & { children: Array<(
      { __typename?: 'CommentWithoutChild' }
      & Pick<CommentWithoutChild, 'timestamp' | 'id' | 'userId' | 'username' | 'content' | 'byVerifiedUser'>
    )> }
  ) }
);

export type CommentsQueryVariables = Exact<{
  input: CommentInput;
}>;


export type CommentsQuery = (
  { __typename?: 'Query' }
  & { comments: Array<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'id' | 'timestamp' | 'content' | 'userId' | 'username' | 'byVerifiedUser' | 'childrenCount' | 'pinned' | 'pinnedBy' | 'photoURL'>
    & { children: Array<(
      { __typename?: 'CommentWithoutChild' }
      & Pick<CommentWithoutChild, 'timestamp' | 'id' | 'userId' | 'username' | 'content' | 'byVerifiedUser'>
    )> }
  )> }
);

export type ExistsMoreCommentsQueryVariables = Exact<{
  input: CommentsWithoutPagination;
}>;


export type ExistsMoreCommentsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'existsMoreComments'>
);

export type CreateResourceMutationVariables = Exact<{
  input: CreateResourceInput;
}>;


export type CreateResourceMutation = (
  { __typename?: 'Mutation' }
  & { createResource: (
    { __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'verified' | 'title' | 'description' | 'updatedAt' | 'contact' | 'address'>
    & { location: (
      { __typename?: 'ResourceLocation' }
      & Pick<ResourceLocation, 'state' | 'district' | 'pincode'>
    ) }
  ) }
);

export type UpdateResourceMutationVariables = Exact<{
  input: UpdateResourceInput;
}>;


export type UpdateResourceMutation = (
  { __typename?: 'Mutation' }
  & { updateResource: (
    { __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'verified' | 'title' | 'description' | 'updatedAt' | 'contact' | 'address'>
    & { location: (
      { __typename?: 'ResourceLocation' }
      & Pick<ResourceLocation, 'state' | 'district' | 'pincode'>
    ) }
  ) }
);

export type DeleteResourceMutationVariables = Exact<{
  input: DeleteResourceInput;
}>;


export type DeleteResourceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteResource'>
);

export type GetLikesQueryVariables = Exact<{
  input: LikeInput;
}>;


export type GetLikesQuery = (
  { __typename?: 'Query' }
  & { likes: (
    { __typename?: 'Like' }
    & Pick<Like, 'count' | 'likedByUser' | 'id'>
  ) }
);

export type AddLikesMutationVariables = Exact<{
  input: AddLikeInput;
}>;


export type AddLikesMutation = (
  { __typename?: 'Mutation' }
  & { addLike: (
    { __typename?: 'Like' }
    & Pick<Like, 'count' | 'likedByUser' | 'id'>
  ) }
);

export type GetStatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStatesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getStates'>
);

export type GetDistrictsQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type GetDistrictsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDistricts'>
);

export type GetPincodesQueryVariables = Exact<{
  input: Scalars['String'];
}>;


export type GetPincodesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPincodes'>
);

export type MiscResourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type MiscResourcesQuery = (
  { __typename?: 'Query' }
  & { miscResources: Array<(
    { __typename?: 'MiscResource' }
    & Pick<MiscResource, 'name' | 'title'>
  )> }
);

export type GetResourceQueryVariables = Exact<{
  input: ResourceId;
}>;


export type GetResourceQuery = (
  { __typename?: 'Query' }
  & { getResource: (
    { __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'verified' | 'title' | 'description' | 'updatedAt' | 'contact' | 'address' | 'type' | 'byUser'>
    & { location: (
      { __typename?: 'ResourceLocation' }
      & Pick<ResourceLocation, 'state' | 'district' | 'pincode'>
    ), bedData?: Maybe<(
      { __typename?: 'BedData' }
      & Pick<BedData, 'normal' | 'oxygen' | 'icu' | 'ventilator'>
    )>, oxygenData?: Maybe<(
      { __typename?: 'OxygenData' }
      & Pick<OxygenData, 'refill' | 'cylinder' | 'can'>
    )>, foodData?: Maybe<(
      { __typename?: 'FoodData' }
      & Pick<FoodData, 'pureVeg' | 'homeDelivery'>
    )>, coordinates: (
      { __typename?: 'LatLng' }
      & Pick<LatLng, 'lat' | 'lng'>
    ) }
  ) }
);

export type GetResourceByLocationQueryVariables = Exact<{
  input: ResourceByLocationInput;
}>;


export type GetResourceByLocationQuery = (
  { __typename?: 'Query' }
  & { getResourceByLocation: Array<(
    { __typename?: 'Resource' }
    & Pick<Resource, 'byUser' | 'id' | 'verified' | 'title' | 'description' | 'updatedAt' | 'contact' | 'address' | 'type'>
    & { location: (
      { __typename?: 'ResourceLocation' }
      & Pick<ResourceLocation, 'state' | 'district' | 'pincode'>
    ), bedData?: Maybe<(
      { __typename?: 'BedData' }
      & Pick<BedData, 'normal' | 'oxygen' | 'icu' | 'ventilator'>
    )>, oxygenData?: Maybe<(
      { __typename?: 'OxygenData' }
      & Pick<OxygenData, 'refill' | 'cylinder' | 'can'>
    )>, foodData?: Maybe<(
      { __typename?: 'FoodData' }
      & Pick<FoodData, 'pureVeg' | 'homeDelivery'>
    )>, coordinates: (
      { __typename?: 'LatLng' }
      & Pick<LatLng, 'lng' | 'lat'>
    ) }
  )> }
);

export type GetResourcesByUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetResourcesByUserQuery = (
  { __typename?: 'Query' }
  & { getResourcesByUser: Array<(
    { __typename?: 'Resource' }
    & Pick<Resource, 'id' | 'verified' | 'title' | 'description' | 'updatedAt' | 'contact' | 'address' | 'type' | 'byUser'>
    & { location: (
      { __typename?: 'ResourceLocation' }
      & Pick<ResourceLocation, 'state' | 'district' | 'pincode'>
    ), bedData?: Maybe<(
      { __typename?: 'BedData' }
      & Pick<BedData, 'normal' | 'oxygen' | 'icu' | 'ventilator'>
    )>, oxygenData?: Maybe<(
      { __typename?: 'OxygenData' }
      & Pick<OxygenData, 'refill' | 'cylinder' | 'can'>
    )>, foodData?: Maybe<(
      { __typename?: 'FoodData' }
      & Pick<FoodData, 'pureVeg' | 'homeDelivery'>
    )>, coordinates: (
      { __typename?: 'LatLng' }
      & Pick<LatLng, 'lng' | 'lat'>
    ) }
  )> }
);

export type ExistsMoreResourcesQueryVariables = Exact<{
  input: ResourceByLocationWithoutPagination;
}>;


export type ExistsMoreResourcesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'existsMoreResources'>
);

export type FetchUserDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchUserDetailsQuery = (
  { __typename?: 'Query' }
  & { userDetails: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'email' | 'leadId' | 'vendorName' | 'phoneNumber'>
    & { vendorLocation: (
      { __typename?: 'VendorLocation' }
      & Pick<VendorLocation, 'state' | 'district'>
    ) }
  ) }
);

export type UnapprovedUsersQueryVariables = Exact<{
  input?: Maybe<UnapprovedUserFilters>;
}>;


export type UnapprovedUsersQuery = (
  { __typename?: 'Query' }
  & { unapprovedUsers: Array<(
    { __typename?: 'UnapprovedUser' }
    & Pick<UnapprovedUser, 'id' | 'vendorName' | 'role' | 'email' | 'phoneNumber'>
    & { vendorLocation: (
      { __typename?: 'VendorLocation' }
      & Pick<VendorLocation, 'state' | 'district'>
    ) }
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: UserMutation;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'email' | 'leadId' | 'vendorName'>
  ) }
);

export type CreateUserWithRoleMutationVariables = Exact<{
  input: UserRoleMutation;
}>;


export type CreateUserWithRoleMutation = (
  { __typename?: 'Mutation' }
  & { createUserWithRole: (
    { __typename?: 'User' }
    & Pick<User, 'name' | 'email' | 'leadId' | 'vendorName'>
  ) }
);

export type ApproveUserMutationVariables = Exact<{
  input: UnapprovedUserMutation;
}>;


export type ApproveUserMutation = (
  { __typename?: 'Mutation' }
  & { approveUser: (
    { __typename?: 'UnapprovedUser' }
    & Pick<UnapprovedUser, 'id' | 'name' | 'email' | 'vendorName' | 'role'>
    & { vendorLocation: (
      { __typename?: 'VendorLocation' }
      & Pick<VendorLocation, 'state' | 'district'>
    ) }
  ) }
);


export const GetBroadcastSubscriptionDocument = gql`
    query getBroadcastSubscription($input: GetSubscriptionInput!) {
  getBroadcastSubscription(input: $input) {
    state
    district
    pincode
  }
}
    `;

/**
 * __useGetBroadcastSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetBroadcastSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBroadcastSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBroadcastSubscriptionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBroadcastSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<GetBroadcastSubscriptionQuery, GetBroadcastSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBroadcastSubscriptionQuery, GetBroadcastSubscriptionQueryVariables>(GetBroadcastSubscriptionDocument, options);
      }
export function useGetBroadcastSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBroadcastSubscriptionQuery, GetBroadcastSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBroadcastSubscriptionQuery, GetBroadcastSubscriptionQueryVariables>(GetBroadcastSubscriptionDocument, options);
        }
export type GetBroadcastSubscriptionQueryHookResult = ReturnType<typeof useGetBroadcastSubscriptionQuery>;
export type GetBroadcastSubscriptionLazyQueryHookResult = ReturnType<typeof useGetBroadcastSubscriptionLazyQuery>;
export type GetBroadcastSubscriptionQueryResult = Apollo.QueryResult<GetBroadcastSubscriptionQuery, GetBroadcastSubscriptionQueryVariables>;
export const SubscribeBroadcastNotificationDocument = gql`
    mutation subscribeBroadcastNotification($input: BroadcastNotificationInput!) {
  subscribeBroadcastNotification(input: $input) {
    success
  }
}
    `;
export type SubscribeBroadcastNotificationMutationFn = Apollo.MutationFunction<SubscribeBroadcastNotificationMutation, SubscribeBroadcastNotificationMutationVariables>;

/**
 * __useSubscribeBroadcastNotificationMutation__
 *
 * To run a mutation, you first call `useSubscribeBroadcastNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeBroadcastNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeBroadcastNotificationMutation, { data, loading, error }] = useSubscribeBroadcastNotificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeBroadcastNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeBroadcastNotificationMutation, SubscribeBroadcastNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubscribeBroadcastNotificationMutation, SubscribeBroadcastNotificationMutationVariables>(SubscribeBroadcastNotificationDocument, options);
      }
export type SubscribeBroadcastNotificationMutationHookResult = ReturnType<typeof useSubscribeBroadcastNotificationMutation>;
export type SubscribeBroadcastNotificationMutationResult = Apollo.MutationResult<SubscribeBroadcastNotificationMutation>;
export type SubscribeBroadcastNotificationMutationOptions = Apollo.BaseMutationOptions<SubscribeBroadcastNotificationMutation, SubscribeBroadcastNotificationMutationVariables>;
export const UpdateBroadcastSubscriptionDocument = gql`
    mutation updateBroadcastSubscription($input: UpdateBroadcastNotificationInput!) {
  updateBroadcastSubscription(input: $input) {
    success
  }
}
    `;
export type UpdateBroadcastSubscriptionMutationFn = Apollo.MutationFunction<UpdateBroadcastSubscriptionMutation, UpdateBroadcastSubscriptionMutationVariables>;

/**
 * __useUpdateBroadcastSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateBroadcastSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBroadcastSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBroadcastSubscriptionMutation, { data, loading, error }] = useUpdateBroadcastSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBroadcastSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBroadcastSubscriptionMutation, UpdateBroadcastSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBroadcastSubscriptionMutation, UpdateBroadcastSubscriptionMutationVariables>(UpdateBroadcastSubscriptionDocument, options);
      }
export type UpdateBroadcastSubscriptionMutationHookResult = ReturnType<typeof useUpdateBroadcastSubscriptionMutation>;
export type UpdateBroadcastSubscriptionMutationResult = Apollo.MutationResult<UpdateBroadcastSubscriptionMutation>;
export type UpdateBroadcastSubscriptionMutationOptions = Apollo.BaseMutationOptions<UpdateBroadcastSubscriptionMutation, UpdateBroadcastSubscriptionMutationVariables>;
export const GetBroadcastsByLocationDocument = gql`
    query getBroadcastsByLocation($input: BroadcastByLocationInput!) {
  getBroadcastsByLocation(input: $input) {
    title
    description
    updatedAt
    location {
      state
      district
      pincodes
    }
    id
  }
}
    `;

/**
 * __useGetBroadcastsByLocationQuery__
 *
 * To run a query within a React component, call `useGetBroadcastsByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBroadcastsByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBroadcastsByLocationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBroadcastsByLocationQuery(baseOptions: Apollo.QueryHookOptions<GetBroadcastsByLocationQuery, GetBroadcastsByLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBroadcastsByLocationQuery, GetBroadcastsByLocationQueryVariables>(GetBroadcastsByLocationDocument, options);
      }
export function useGetBroadcastsByLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBroadcastsByLocationQuery, GetBroadcastsByLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBroadcastsByLocationQuery, GetBroadcastsByLocationQueryVariables>(GetBroadcastsByLocationDocument, options);
        }
export type GetBroadcastsByLocationQueryHookResult = ReturnType<typeof useGetBroadcastsByLocationQuery>;
export type GetBroadcastsByLocationLazyQueryHookResult = ReturnType<typeof useGetBroadcastsByLocationLazyQuery>;
export type GetBroadcastsByLocationQueryResult = Apollo.QueryResult<GetBroadcastsByLocationQuery, GetBroadcastsByLocationQueryVariables>;
export const GetBroadcastDocument = gql`
    query getBroadcast($input: BroadcastId!) {
  getBroadcast(input: $input) {
    title
    description
    updatedAt
    location {
      state
      district
      pincodes
    }
    id
  }
}
    `;

/**
 * __useGetBroadcastQuery__
 *
 * To run a query within a React component, call `useGetBroadcastQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBroadcastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBroadcastQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetBroadcastQuery(baseOptions: Apollo.QueryHookOptions<GetBroadcastQuery, GetBroadcastQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBroadcastQuery, GetBroadcastQueryVariables>(GetBroadcastDocument, options);
      }
export function useGetBroadcastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBroadcastQuery, GetBroadcastQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBroadcastQuery, GetBroadcastQueryVariables>(GetBroadcastDocument, options);
        }
export type GetBroadcastQueryHookResult = ReturnType<typeof useGetBroadcastQuery>;
export type GetBroadcastLazyQueryHookResult = ReturnType<typeof useGetBroadcastLazyQuery>;
export type GetBroadcastQueryResult = Apollo.QueryResult<GetBroadcastQuery, GetBroadcastQueryVariables>;
export const ExistsMoreBroadcastsDocument = gql`
    query existsMoreBroadcasts($input: BroadcastLocationInput!) {
  existsMore(input: $input) @client
}
    `;

/**
 * __useExistsMoreBroadcastsQuery__
 *
 * To run a query within a React component, call `useExistsMoreBroadcastsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistsMoreBroadcastsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistsMoreBroadcastsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExistsMoreBroadcastsQuery(baseOptions: Apollo.QueryHookOptions<ExistsMoreBroadcastsQuery, ExistsMoreBroadcastsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExistsMoreBroadcastsQuery, ExistsMoreBroadcastsQueryVariables>(ExistsMoreBroadcastsDocument, options);
      }
export function useExistsMoreBroadcastsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExistsMoreBroadcastsQuery, ExistsMoreBroadcastsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExistsMoreBroadcastsQuery, ExistsMoreBroadcastsQueryVariables>(ExistsMoreBroadcastsDocument, options);
        }
export type ExistsMoreBroadcastsQueryHookResult = ReturnType<typeof useExistsMoreBroadcastsQuery>;
export type ExistsMoreBroadcastsLazyQueryHookResult = ReturnType<typeof useExistsMoreBroadcastsLazyQuery>;
export type ExistsMoreBroadcastsQueryResult = Apollo.QueryResult<ExistsMoreBroadcastsQuery, ExistsMoreBroadcastsQueryVariables>;
export const CreateBroadcastDocument = gql`
    mutation createBroadcast($input: BroadcastMutation!) {
  createBroadcast(input: $input) {
    title
    description
    updatedAt
    location {
      pincodes
      state
      district
    }
    id
  }
}
    `;
export type CreateBroadcastMutationFn = Apollo.MutationFunction<CreateBroadcastMutation, CreateBroadcastMutationVariables>;

/**
 * __useCreateBroadcastMutation__
 *
 * To run a mutation, you first call `useCreateBroadcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBroadcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBroadcastMutation, { data, loading, error }] = useCreateBroadcastMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBroadcastMutation(baseOptions?: Apollo.MutationHookOptions<CreateBroadcastMutation, CreateBroadcastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBroadcastMutation, CreateBroadcastMutationVariables>(CreateBroadcastDocument, options);
      }
export type CreateBroadcastMutationHookResult = ReturnType<typeof useCreateBroadcastMutation>;
export type CreateBroadcastMutationResult = Apollo.MutationResult<CreateBroadcastMutation>;
export type CreateBroadcastMutationOptions = Apollo.BaseMutationOptions<CreateBroadcastMutation, CreateBroadcastMutationVariables>;
export const AddCommentDocument = gql`
    mutation addComment($input: AddCommentInput!) {
  addComment(input: $input) {
    id
    timestamp
    content
    userId
    username
    byVerifiedUser
    children {
      timestamp
      id
      userId
      username
      content
      byVerifiedUser
    }
    childrenCount
    pinned
    pinnedBy
    photoURL
  }
}
    `;
export type AddCommentMutationFn = Apollo.MutationFunction<AddCommentMutation, AddCommentMutationVariables>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentMutation, AddCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentMutation, AddCommentMutationVariables>(AddCommentDocument, options);
      }
export type AddCommentMutationHookResult = ReturnType<typeof useAddCommentMutation>;
export type AddCommentMutationResult = Apollo.MutationResult<AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<AddCommentMutation, AddCommentMutationVariables>;
export const CommentsDocument = gql`
    query comments($input: CommentInput!) {
  comments(input: $input) {
    id
    timestamp
    content
    userId
    username
    byVerifiedUser
    childrenCount
    children {
      timestamp
      id
      userId
      username
      content
      byVerifiedUser
    }
    pinned
    pinnedBy
    photoURL
  }
}
    `;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions: Apollo.QueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
      }
export function useCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, options);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsQueryResult = Apollo.QueryResult<CommentsQuery, CommentsQueryVariables>;
export const ExistsMoreCommentsDocument = gql`
    query existsMoreComments($input: CommentsWithoutPagination!) {
  existsMoreComments(input: $input) @client
}
    `;

/**
 * __useExistsMoreCommentsQuery__
 *
 * To run a query within a React component, call `useExistsMoreCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistsMoreCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistsMoreCommentsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExistsMoreCommentsQuery(baseOptions: Apollo.QueryHookOptions<ExistsMoreCommentsQuery, ExistsMoreCommentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExistsMoreCommentsQuery, ExistsMoreCommentsQueryVariables>(ExistsMoreCommentsDocument, options);
      }
export function useExistsMoreCommentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExistsMoreCommentsQuery, ExistsMoreCommentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExistsMoreCommentsQuery, ExistsMoreCommentsQueryVariables>(ExistsMoreCommentsDocument, options);
        }
export type ExistsMoreCommentsQueryHookResult = ReturnType<typeof useExistsMoreCommentsQuery>;
export type ExistsMoreCommentsLazyQueryHookResult = ReturnType<typeof useExistsMoreCommentsLazyQuery>;
export type ExistsMoreCommentsQueryResult = Apollo.QueryResult<ExistsMoreCommentsQuery, ExistsMoreCommentsQueryVariables>;
export const CreateResourceDocument = gql`
    mutation createResource($input: CreateResourceInput!) {
  createResource(input: $input) {
    id
    location {
      state
      district
      pincode
    }
    verified
    title
    description
    updatedAt
    contact
    address
  }
}
    `;
export type CreateResourceMutationFn = Apollo.MutationFunction<CreateResourceMutation, CreateResourceMutationVariables>;

/**
 * __useCreateResourceMutation__
 *
 * To run a mutation, you first call `useCreateResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourceMutation, { data, loading, error }] = useCreateResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResourceMutation(baseOptions?: Apollo.MutationHookOptions<CreateResourceMutation, CreateResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResourceMutation, CreateResourceMutationVariables>(CreateResourceDocument, options);
      }
export type CreateResourceMutationHookResult = ReturnType<typeof useCreateResourceMutation>;
export type CreateResourceMutationResult = Apollo.MutationResult<CreateResourceMutation>;
export type CreateResourceMutationOptions = Apollo.BaseMutationOptions<CreateResourceMutation, CreateResourceMutationVariables>;
export const UpdateResourceDocument = gql`
    mutation updateResource($input: UpdateResourceInput!) {
  updateResource(input: $input) {
    id
    location {
      state
      district
      pincode
    }
    verified
    title
    description
    updatedAt
    contact
    address
  }
}
    `;
export type UpdateResourceMutationFn = Apollo.MutationFunction<UpdateResourceMutation, UpdateResourceMutationVariables>;

/**
 * __useUpdateResourceMutation__
 *
 * To run a mutation, you first call `useUpdateResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceMutation, { data, loading, error }] = useUpdateResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResourceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResourceMutation, UpdateResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResourceMutation, UpdateResourceMutationVariables>(UpdateResourceDocument, options);
      }
export type UpdateResourceMutationHookResult = ReturnType<typeof useUpdateResourceMutation>;
export type UpdateResourceMutationResult = Apollo.MutationResult<UpdateResourceMutation>;
export type UpdateResourceMutationOptions = Apollo.BaseMutationOptions<UpdateResourceMutation, UpdateResourceMutationVariables>;
export const DeleteResourceDocument = gql`
    mutation deleteResource($input: DeleteResourceInput!) {
  deleteResource(input: $input)
}
    `;
export type DeleteResourceMutationFn = Apollo.MutationFunction<DeleteResourceMutation, DeleteResourceMutationVariables>;

/**
 * __useDeleteResourceMutation__
 *
 * To run a mutation, you first call `useDeleteResourceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResourceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResourceMutation, { data, loading, error }] = useDeleteResourceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteResourceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResourceMutation, DeleteResourceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResourceMutation, DeleteResourceMutationVariables>(DeleteResourceDocument, options);
      }
export type DeleteResourceMutationHookResult = ReturnType<typeof useDeleteResourceMutation>;
export type DeleteResourceMutationResult = Apollo.MutationResult<DeleteResourceMutation>;
export type DeleteResourceMutationOptions = Apollo.BaseMutationOptions<DeleteResourceMutation, DeleteResourceMutationVariables>;
export const GetLikesDocument = gql`
    query getLikes($input: LikeInput!) {
  likes(input: $input) {
    count
    likedByUser
    id
  }
}
    `;

/**
 * __useGetLikesQuery__
 *
 * To run a query within a React component, call `useGetLikesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLikesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLikesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLikesQuery(baseOptions: Apollo.QueryHookOptions<GetLikesQuery, GetLikesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLikesQuery, GetLikesQueryVariables>(GetLikesDocument, options);
      }
export function useGetLikesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLikesQuery, GetLikesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLikesQuery, GetLikesQueryVariables>(GetLikesDocument, options);
        }
export type GetLikesQueryHookResult = ReturnType<typeof useGetLikesQuery>;
export type GetLikesLazyQueryHookResult = ReturnType<typeof useGetLikesLazyQuery>;
export type GetLikesQueryResult = Apollo.QueryResult<GetLikesQuery, GetLikesQueryVariables>;
export const AddLikesDocument = gql`
    mutation addLikes($input: AddLikeInput!) {
  addLike(input: $input) {
    count
    likedByUser
    id
  }
}
    `;
export type AddLikesMutationFn = Apollo.MutationFunction<AddLikesMutation, AddLikesMutationVariables>;

/**
 * __useAddLikesMutation__
 *
 * To run a mutation, you first call `useAddLikesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLikesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLikesMutation, { data, loading, error }] = useAddLikesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddLikesMutation(baseOptions?: Apollo.MutationHookOptions<AddLikesMutation, AddLikesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddLikesMutation, AddLikesMutationVariables>(AddLikesDocument, options);
      }
export type AddLikesMutationHookResult = ReturnType<typeof useAddLikesMutation>;
export type AddLikesMutationResult = Apollo.MutationResult<AddLikesMutation>;
export type AddLikesMutationOptions = Apollo.BaseMutationOptions<AddLikesMutation, AddLikesMutationVariables>;
export const GetStatesDocument = gql`
    query getStates {
  getStates
}
    `;

/**
 * __useGetStatesQuery__
 *
 * To run a query within a React component, call `useGetStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatesQuery(baseOptions?: Apollo.QueryHookOptions<GetStatesQuery, GetStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatesQuery, GetStatesQueryVariables>(GetStatesDocument, options);
      }
export function useGetStatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatesQuery, GetStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatesQuery, GetStatesQueryVariables>(GetStatesDocument, options);
        }
export type GetStatesQueryHookResult = ReturnType<typeof useGetStatesQuery>;
export type GetStatesLazyQueryHookResult = ReturnType<typeof useGetStatesLazyQuery>;
export type GetStatesQueryResult = Apollo.QueryResult<GetStatesQuery, GetStatesQueryVariables>;
export const GetDistrictsDocument = gql`
    query getDistricts($input: String!) {
  getDistricts(state: $input)
}
    `;

/**
 * __useGetDistrictsQuery__
 *
 * To run a query within a React component, call `useGetDistrictsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDistrictsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDistrictsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetDistrictsQuery(baseOptions: Apollo.QueryHookOptions<GetDistrictsQuery, GetDistrictsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDistrictsQuery, GetDistrictsQueryVariables>(GetDistrictsDocument, options);
      }
export function useGetDistrictsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDistrictsQuery, GetDistrictsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDistrictsQuery, GetDistrictsQueryVariables>(GetDistrictsDocument, options);
        }
export type GetDistrictsQueryHookResult = ReturnType<typeof useGetDistrictsQuery>;
export type GetDistrictsLazyQueryHookResult = ReturnType<typeof useGetDistrictsLazyQuery>;
export type GetDistrictsQueryResult = Apollo.QueryResult<GetDistrictsQuery, GetDistrictsQueryVariables>;
export const GetPincodesDocument = gql`
    query getPincodes($input: String!) {
  getPincodes(input: $input)
}
    `;

/**
 * __useGetPincodesQuery__
 *
 * To run a query within a React component, call `useGetPincodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPincodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPincodesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetPincodesQuery(baseOptions: Apollo.QueryHookOptions<GetPincodesQuery, GetPincodesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPincodesQuery, GetPincodesQueryVariables>(GetPincodesDocument, options);
      }
export function useGetPincodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPincodesQuery, GetPincodesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPincodesQuery, GetPincodesQueryVariables>(GetPincodesDocument, options);
        }
export type GetPincodesQueryHookResult = ReturnType<typeof useGetPincodesQuery>;
export type GetPincodesLazyQueryHookResult = ReturnType<typeof useGetPincodesLazyQuery>;
export type GetPincodesQueryResult = Apollo.QueryResult<GetPincodesQuery, GetPincodesQueryVariables>;
export const MiscResourcesDocument = gql`
    query MiscResources {
  miscResources {
    name
    title
  }
}
    `;

/**
 * __useMiscResourcesQuery__
 *
 * To run a query within a React component, call `useMiscResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMiscResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMiscResourcesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMiscResourcesQuery(baseOptions?: Apollo.QueryHookOptions<MiscResourcesQuery, MiscResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MiscResourcesQuery, MiscResourcesQueryVariables>(MiscResourcesDocument, options);
      }
export function useMiscResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MiscResourcesQuery, MiscResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MiscResourcesQuery, MiscResourcesQueryVariables>(MiscResourcesDocument, options);
        }
export type MiscResourcesQueryHookResult = ReturnType<typeof useMiscResourcesQuery>;
export type MiscResourcesLazyQueryHookResult = ReturnType<typeof useMiscResourcesLazyQuery>;
export type MiscResourcesQueryResult = Apollo.QueryResult<MiscResourcesQuery, MiscResourcesQueryVariables>;
export const GetResourceDocument = gql`
    query getResource($input: ResourceId!) {
  getResource(input: $input) {
    id
    location {
      state
      district
      pincode
    }
    verified
    title
    description
    updatedAt
    contact
    address
    type
    bedData {
      normal
      oxygen
      icu
      ventilator
    }
    oxygenData {
      refill
      cylinder
      can
    }
    foodData {
      pureVeg
      homeDelivery
    }
    byUser
    coordinates {
      lat
      lng
    }
  }
}
    `;

/**
 * __useGetResourceQuery__
 *
 * To run a query within a React component, call `useGetResourceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetResourceQuery(baseOptions: Apollo.QueryHookOptions<GetResourceQuery, GetResourceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceQuery, GetResourceQueryVariables>(GetResourceDocument, options);
      }
export function useGetResourceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceQuery, GetResourceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceQuery, GetResourceQueryVariables>(GetResourceDocument, options);
        }
export type GetResourceQueryHookResult = ReturnType<typeof useGetResourceQuery>;
export type GetResourceLazyQueryHookResult = ReturnType<typeof useGetResourceLazyQuery>;
export type GetResourceQueryResult = Apollo.QueryResult<GetResourceQuery, GetResourceQueryVariables>;
export const GetResourceByLocationDocument = gql`
    query getResourceByLocation($input: ResourceByLocationInput!) {
  getResourceByLocation(input: $input) {
    byUser
    id
    location {
      state
      district
      pincode
    }
    verified
    title
    description
    updatedAt
    contact
    address
    type
    bedData {
      normal
      oxygen
      icu
      ventilator
    }
    oxygenData {
      refill
      cylinder
      can
    }
    foodData {
      pureVeg
      homeDelivery
    }
    coordinates {
      lng
      lat
    }
  }
}
    `;

/**
 * __useGetResourceByLocationQuery__
 *
 * To run a query within a React component, call `useGetResourceByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourceByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourceByLocationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetResourceByLocationQuery(baseOptions: Apollo.QueryHookOptions<GetResourceByLocationQuery, GetResourceByLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourceByLocationQuery, GetResourceByLocationQueryVariables>(GetResourceByLocationDocument, options);
      }
export function useGetResourceByLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourceByLocationQuery, GetResourceByLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourceByLocationQuery, GetResourceByLocationQueryVariables>(GetResourceByLocationDocument, options);
        }
export type GetResourceByLocationQueryHookResult = ReturnType<typeof useGetResourceByLocationQuery>;
export type GetResourceByLocationLazyQueryHookResult = ReturnType<typeof useGetResourceByLocationLazyQuery>;
export type GetResourceByLocationQueryResult = Apollo.QueryResult<GetResourceByLocationQuery, GetResourceByLocationQueryVariables>;
export const GetResourcesByUserDocument = gql`
    query getResourcesByUser {
  getResourcesByUser {
    id
    location {
      state
      district
      pincode
    }
    verified
    title
    description
    updatedAt
    contact
    address
    type
    bedData {
      normal
      oxygen
      icu
      ventilator
    }
    oxygenData {
      refill
      cylinder
      can
    }
    foodData {
      pureVeg
      homeDelivery
    }
    byUser
    coordinates {
      lng
      lat
    }
  }
}
    `;

/**
 * __useGetResourcesByUserQuery__
 *
 * To run a query within a React component, call `useGetResourcesByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetResourcesByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetResourcesByUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetResourcesByUserQuery(baseOptions?: Apollo.QueryHookOptions<GetResourcesByUserQuery, GetResourcesByUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetResourcesByUserQuery, GetResourcesByUserQueryVariables>(GetResourcesByUserDocument, options);
      }
export function useGetResourcesByUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetResourcesByUserQuery, GetResourcesByUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetResourcesByUserQuery, GetResourcesByUserQueryVariables>(GetResourcesByUserDocument, options);
        }
export type GetResourcesByUserQueryHookResult = ReturnType<typeof useGetResourcesByUserQuery>;
export type GetResourcesByUserLazyQueryHookResult = ReturnType<typeof useGetResourcesByUserLazyQuery>;
export type GetResourcesByUserQueryResult = Apollo.QueryResult<GetResourcesByUserQuery, GetResourcesByUserQueryVariables>;
export const ExistsMoreResourcesDocument = gql`
    query existsMoreResources($input: ResourceByLocationWithoutPagination!) {
  existsMoreResources(input: $input) @client
}
    `;

/**
 * __useExistsMoreResourcesQuery__
 *
 * To run a query within a React component, call `useExistsMoreResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistsMoreResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExistsMoreResourcesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExistsMoreResourcesQuery(baseOptions: Apollo.QueryHookOptions<ExistsMoreResourcesQuery, ExistsMoreResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExistsMoreResourcesQuery, ExistsMoreResourcesQueryVariables>(ExistsMoreResourcesDocument, options);
      }
export function useExistsMoreResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExistsMoreResourcesQuery, ExistsMoreResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExistsMoreResourcesQuery, ExistsMoreResourcesQueryVariables>(ExistsMoreResourcesDocument, options);
        }
export type ExistsMoreResourcesQueryHookResult = ReturnType<typeof useExistsMoreResourcesQuery>;
export type ExistsMoreResourcesLazyQueryHookResult = ReturnType<typeof useExistsMoreResourcesLazyQuery>;
export type ExistsMoreResourcesQueryResult = Apollo.QueryResult<ExistsMoreResourcesQuery, ExistsMoreResourcesQueryVariables>;
export const FetchUserDetailsDocument = gql`
    query fetchUserDetails {
  userDetails {
    name
    email
    leadId
    vendorName
    vendorLocation {
      state
      district
    }
    phoneNumber
  }
}
    `;

/**
 * __useFetchUserDetailsQuery__
 *
 * To run a query within a React component, call `useFetchUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchUserDetailsQuery(baseOptions?: Apollo.QueryHookOptions<FetchUserDetailsQuery, FetchUserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchUserDetailsQuery, FetchUserDetailsQueryVariables>(FetchUserDetailsDocument, options);
      }
export function useFetchUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchUserDetailsQuery, FetchUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchUserDetailsQuery, FetchUserDetailsQueryVariables>(FetchUserDetailsDocument, options);
        }
export type FetchUserDetailsQueryHookResult = ReturnType<typeof useFetchUserDetailsQuery>;
export type FetchUserDetailsLazyQueryHookResult = ReturnType<typeof useFetchUserDetailsLazyQuery>;
export type FetchUserDetailsQueryResult = Apollo.QueryResult<FetchUserDetailsQuery, FetchUserDetailsQueryVariables>;
export const UnapprovedUsersDocument = gql`
    query UnapprovedUsers($input: UnapprovedUserFilters) {
  unapprovedUsers(input: $input) {
    id
    vendorName
    role
    email
    vendorLocation {
      state
      district
    }
    phoneNumber
  }
}
    `;

/**
 * __useUnapprovedUsersQuery__
 *
 * To run a query within a React component, call `useUnapprovedUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnapprovedUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnapprovedUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnapprovedUsersQuery(baseOptions?: Apollo.QueryHookOptions<UnapprovedUsersQuery, UnapprovedUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnapprovedUsersQuery, UnapprovedUsersQueryVariables>(UnapprovedUsersDocument, options);
      }
export function useUnapprovedUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnapprovedUsersQuery, UnapprovedUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnapprovedUsersQuery, UnapprovedUsersQueryVariables>(UnapprovedUsersDocument, options);
        }
export type UnapprovedUsersQueryHookResult = ReturnType<typeof useUnapprovedUsersQuery>;
export type UnapprovedUsersLazyQueryHookResult = ReturnType<typeof useUnapprovedUsersLazyQuery>;
export type UnapprovedUsersQueryResult = Apollo.QueryResult<UnapprovedUsersQuery, UnapprovedUsersQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: UserMutation!) {
  createUser(input: $input) {
    name
    email
    leadId
    vendorName
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreateUserWithRoleDocument = gql`
    mutation createUserWithRole($input: UserRoleMutation!) {
  createUserWithRole(input: $input) {
    name
    email
    leadId
    vendorName
  }
}
    `;
export type CreateUserWithRoleMutationFn = Apollo.MutationFunction<CreateUserWithRoleMutation, CreateUserWithRoleMutationVariables>;

/**
 * __useCreateUserWithRoleMutation__
 *
 * To run a mutation, you first call `useCreateUserWithRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserWithRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserWithRoleMutation, { data, loading, error }] = useCreateUserWithRoleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserWithRoleMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserWithRoleMutation, CreateUserWithRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserWithRoleMutation, CreateUserWithRoleMutationVariables>(CreateUserWithRoleDocument, options);
      }
export type CreateUserWithRoleMutationHookResult = ReturnType<typeof useCreateUserWithRoleMutation>;
export type CreateUserWithRoleMutationResult = Apollo.MutationResult<CreateUserWithRoleMutation>;
export type CreateUserWithRoleMutationOptions = Apollo.BaseMutationOptions<CreateUserWithRoleMutation, CreateUserWithRoleMutationVariables>;
export const ApproveUserDocument = gql`
    mutation approveUser($input: UnapprovedUserMutation!) {
  approveUser(input: $input) {
    id
    name
    email
    vendorName
    vendorLocation {
      state
      district
    }
    role
  }
}
    `;
export type ApproveUserMutationFn = Apollo.MutationFunction<ApproveUserMutation, ApproveUserMutationVariables>;

/**
 * __useApproveUserMutation__
 *
 * To run a mutation, you first call `useApproveUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveUserMutation, { data, loading, error }] = useApproveUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApproveUserMutation(baseOptions?: Apollo.MutationHookOptions<ApproveUserMutation, ApproveUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveUserMutation, ApproveUserMutationVariables>(ApproveUserDocument, options);
      }
export type ApproveUserMutationHookResult = ReturnType<typeof useApproveUserMutation>;
export type ApproveUserMutationResult = Apollo.MutationResult<ApproveUserMutation>;
export type ApproveUserMutationOptions = Apollo.BaseMutationOptions<ApproveUserMutation, ApproveUserMutationVariables>;