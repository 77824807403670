/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useSubscribeBroadcastNotificationMutation } from "../../apollo/gen-types";
import { FirebaseContext } from "../../components/firebase";

export interface ILocationContext {
  broadcastPin: string | null;
  setBroadcastPin: (_arg: string | null) => void;
  state: string | null;
  location: string | null;
  setLocation: (_arg: {
    state: string | null;
    location: string | null;
  }) => void;
}
export const LocationContext = React.createContext<ILocationContext>({
  broadcastPin: null,
  state: null,
  location: null,
  setLocation: () => {},
  setBroadcastPin: () => {},
});

export const LocalStorageProvider: React.FC = ({ ...props }) => {
  const [localStorageState, setLocalStorageState] = useState<{
    state: string | null;
    location: string | null;
  }>({
    state: localStorage.getItem("resourceState"),
    location: localStorage.getItem("resourceLocation"),
  });
  const [broadcastPin, setBroadcast] = useState<string | null>(
    localStorage.getItem("broadcastPin")
  );
  const setLocation = React.useCallback(
    (updatedLocation: {
      state: string | null;
      location: string | null;
    }): void => {
      setLocalStorageState(updatedLocation);
      localStorage.setItem("resourceState", updatedLocation?.state || "");
      localStorage.setItem("resourceLocation", updatedLocation?.location || "");
    },
    []
  );
  const setBroadcastPin = React.useCallback(
    (updatedPin: string | null): void => {
      setBroadcast(updatedPin);
      localStorage.setItem("broadcastPin", updatedPin || "");
    },
    []
  );
  const [subscribeBroadcast] = useSubscribeBroadcastNotificationMutation();

  const firebase = React.useContext(FirebaseContext);

  async function getTokenPromise() {
    const msg = firebase?.messaging;
    const result = msg
      ?.getToken({
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      })
      // eslint-disable-next-line consistent-return
      .then((currentToken) => {
        if (currentToken) {
          return currentToken;
        }
      })
      .catch((err) => {
        return err;
      });

    return result;
  }

  function generateToken() {
    if ("Notification" in window)
      return Promise.resolve(Notification.requestPermission()).then(
        (permission) => {
          if (permission === "granted") return getTokenPromise();
          return null;
        }
      );
    return null;
  }

  useEffect(() => {
    if (localStorageState.state && localStorageState.location) {
      (async () => {
        const token = await generateToken();
        if (token) {
          subscribeBroadcast({
            variables: {
              input: {
                token,
                state: localStorageState.state!,
                district: localStorageState.location!,
              },
            },
          });
        }
      })();
    }
  }, [localStorageState]);

  return (
    <LocationContext.Provider
      value={{
        setLocation,
        ...localStorageState,
        setBroadcastPin,
        broadcastPin,
      }}
    >
      {props.children}
    </LocationContext.Provider>
  );
};

export default LocalStorageProvider;
