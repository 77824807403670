/* eslint-disable no-new */
import React, { FC, Suspense, useEffect } from "react";
import "./App.less";
import "@fontsource/roboto";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { Spinner } from "./components/shared/Spinner";
import { FirebaseContext } from "./components/firebase";

const MainContainer = React.lazy(
  () => import("./components/shared/MainContainer")
);

const LandingPage = React.lazy(() => import("./screens/Landing"));
const SingleResource = React.lazy(() => import("./screens/SingleResource"));
const MyLeads = React.lazy(() => import("./screens/MyLeads"));
const Surveys = React.lazy(() => import("./screens/Surveys"));
const Resources = React.lazy(() => import("./screens/Resources"));
const Broadcast = React.lazy(() => import("./screens/Broadcast"));
const NewBroadcast = React.lazy(() => import("./screens/NewBroadcast"));
const NewLeads = React.lazy(() => import("./screens/NewLeads"));
const ProfilePage = React.lazy(() => import("./screens/ProfilePage"));
const Approval = React.lazy(() => import("./screens/Approval"));
const ResourcesHomepage = React.lazy(
  () => import("./screens/ResourcesHomepage")
);

const wrapRouter = (Component: React.FC) => {
  const WrappedComponent: FC<{}> = () => (
    <Router>
      <Component />
    </Router>
  );
  return WrappedComponent;
};

const Routes: {
  [key: string]: React.LazyExoticComponent<React.FC<any>>;
} = {
  "/account": ProfilePage,
  "/resources": ResourcesHomepage,
  "/resources/:resource": Resources,
  "/surveys": Surveys,
  "/lead/:resourceType/:resourceId": SingleResource,
  "/broadcast": Broadcast,
  "/ApproveRequests": Approval,
  "/leads": MyLeads,
  "/NewLead": NewLeads,
  "/updateLead/:id": NewLeads,
  "/newBroadcast": NewBroadcast,
};

const MainContent: FC<{}> = () => {
  const firebase = React.useContext(FirebaseContext);

  useEffect(() => {
    firebase?.messaging?.onMessage((payload) => {
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon:
          payload.notification.icon ||
          "https://karuna.pathcheck.org/assets/icons/icon-192x192.png",
        vibrate: [200, 100, 300],
        click_action: payload.notification.click_action || null,
        requireInteraction: payload.notification.requireInteraction || true,
      };

      if ("serviceWorker" in navigator) {
        navigator.serviceWorker
          .register("./firebase-messaging-sw.js", { scope: "./" })
          .then((registration) => {
            setTimeout(() => {
              registration.showNotification(
                notificationTitle,
                notificationOptions
              );
              registration.update();
            }, 100);
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log("Service Worker Failed to Register", err);
          });

        // if (!("Notification" in window)) {
        //   console.log("This browser does not support system notifications.");
        // } else if (Notification.permission === "granted") {
        //   // If it's okay let's create a notification
        //   navigator.serviceWorker.ready.then(function (registration) {
        //     registration.showNotification(notificationTitle, notificationOptions);
        //   });

        // notification.onclick = function (event) {
        //   event.preventDefault();
        //   window.open(payload.notification.click_action, "_blank");
        //   notification.close();
        // };
      }
    });
  }, []);

  return (
    <Suspense fallback={<Spinner large />}>
      <Switch>
        {Object.keys(Routes).map((route) => {
          const MainContentComponent = Routes[route];
          return (
            <Route
              path={route}
              exact
              render={() => (
                <MainContentComponent
                  update={route === "/updateLead/:id" ? true : undefined}
                />
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  );
};

export const App: FC = () => {
  return (
    <div style={{ height: "100vh", overflow: "hidden" }}>
      <Suspense fallback={<Spinner large />}>
        <Switch>
          <Route path={["/", "/SignIn"]} exact component={LandingPage} />
          <Route
            path={Object.keys(Routes)}
            exact
            render={() => <MainContainer content={<MainContent />} />}
          />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </div>
  );
};

export default wrapRouter(App);
